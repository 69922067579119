import React, { ReactElement } from "react";
import {
  GatsbyPageProps,
  FixedSharpImageFile,
  FluidSharpImageFile,
  GalleryProps,
  Gallery,
} from "@ymcansw-camping/common";
import { graphql } from "gatsby";
import OurCampLayout from "../../components/our-camp-layout";

type OurCampProps = GatsbyPageProps<{
  readonly bannerHeaderImage: FixedSharpImageFile;
  readonly allFile: GalleryProps["images"];
  readonly videoImage: FluidSharpImageFile;
}>;

export default function OurCampPage({
  data: { bannerHeaderImage, allFile },
}: OurCampProps): ReactElement<OurCampProps> {
  return (
    <OurCampLayout
      meta={{
        title: "About Us",
        description:
          "Since 1937 Camp Yarramundi has been providing Outdoor Education and Camping Programs, Community Engagement Programs and Accommodation and Facilities hire to schools, community groups and families across NSW.",
      }}
      bannerHeaderImage={bannerHeaderImage}
      isIndex
    >
      <div className="content-block">
        <h2>Camp Yarramundi</h2>
        <p>
          Since 1937 Camp Yarramundi has been providing Outdoor Education and
          Camping Programs, Community Engagement Programs and Accommodation and
          Facilities hire to schools, community groups and families across NSW.
        </p>
        <p>
          Camp Yarramundi has had a multi-generational influence with hundreds
          of thousands of people having participated in programs or been
          involved with Camp Yarramundi in some way.
        </p>
        <p>
          Over the past 80 years Camp Yarramundi has left a legacy of providing
          people with memorable adventure learning experiences that last a
          lifetime. The rich and successful history of Camp Yarramundi embodies
          The Y&apos;s belief in the power of inspired young people.
        </p>
      </div>

      <div className="video-box">
        <iframe
          title="Virtual Tour"
          allowFullScreen
          frameBorder="0"
          height="600"
          src="https://www.google.com/maps/embed?pb=!1m0!3m2!1sen!2sau!4v1489203068615!6m8!1m7!1sF%3A-zgw7cemwqqE%2FWKfOstif7gI%2FAAAAAAAAIpw%2FYksNwf-K2ssmY3jdUzYM26aESSFaTtrkQCLIB!2m2!1d-33.61753057894306!2d150.680741071701!3f351.9708682287471!4f-4.206715851389774!5f0.7820865974627469"
          style={{ border: 0 }}
          width="100%"
        />
      </div>

      <Gallery images={allFile} />
    </OurCampLayout>
  );
}

export const query = graphql`
  query OurCampQuery {
    bannerHeaderImage: file(relativePath: { eq: "img/our-camp-bg.jpg" }) {
      ...GeneralPageLayout_bannerHeaderImage
    }
    videoImage: file(relativePath: { eq: "img/camp-video-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 787) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "gallery-images" } }) {
      nodes {
        id
        childMarkdownRemark {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  src
                }
              }
            }
            thumb: image {
              childImageSharp {
                fixed(height: 174, width: 174) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
